import { BarChart, Card, Title } from "@tremor/react";
      
const chartdata3 = [
  {
    date: "Mon",
    "2022": 450,
    "2023": 550,
  },
  {
    date: "tue",
    "2022": 520,
    "2023": 310,
  },
  {
    date: "wed",
    "2022": 248,
    "2023": 580,
  },
  {
    date: "thu",
    "2022": 261,
    "2023": 465,
  },
  {
    date: "fri",
    "2022": 155,
    "2023": 158,
  },
  {
    date: "sat",
    "2022": 367,
    "2023": 462,
  },
  {
    date: "sun",
    "2022": 260,
    "2023": 354,
  },
  {
    date: "mon",
    "2022": 172,
    "2023": 249,
  },
  {
    date: "tue",
    "2022": 465,
    "2023": 452,
  },
  {
    date: "wed",
    "2022": 368,
    "2023": 320,
  },
  {
    date: "thu",
    "2022": 574,
    "2023": 525,
  },
  {
    date: "fri",
    "2022": 371,
    "2023": 254,
  },
];

const DashboardMain = () => {
  return (
    <div className="flex justify-between card-main">
      <Card className="w-1/2 mr-3 card-dashboard">
        <Title>Daily consumition electricity + prediction</Title>
        <BarChart
          className="mt-6"
          data={chartdata3}
          index="date"
          categories={["2022", "2023"]}
          colors={["blue", "violet"]}
          yAxisWidth={30}
        />
      </Card>
      <Card className="w-1/2 card-dashboard">
        <Title>Daily consumition electricity + prediction</Title>
        <BarChart
          className="mt-6"
          data={chartdata3}
          index="date"
          categories={["2022", "2023"]}
          colors={["blue", "violet"]}
          yAxisWidth={30}
        />
      </Card>
    </div>
  );
};

export default DashboardMain