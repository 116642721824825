
import React from "react";
import DashboardMain from "./DashboardMain"


function Main() {
  return (
  <div className="main">
    <DashboardMain />
  </div>
  );
}

export default Main;
