import React from 'react';
import Topbar from "./Components/TopBar/Topbar"
import Leftbar from "./Components/LeftBar/Leftbar"
import Main from "./Components/Main/Main"
import Bottombar from "./Components/BottomBar/Bottombar"




const Dashboard = () => {

  return (
    <div className='dashboard'>
      <Topbar className="top-bar"/>
      <Leftbar className="left-bar"/>
      <Main className="main"/>
      <Bottombar className="bottom-bar"/>
    </div>
  );
};

export default Dashboard;
