import React from 'react'
import Typography from "@material-ui/core/Typography";
import BoxTopBar from './BoxTopBar';
import uniqid from 'uniqid';

const listBoxs = [
  {
    title1: "363.5kwh",
    underTitle1: "Group 1",
    title2: "215.5kwh",
    underTitle2: "Group 2",
    consumption: "Electricity"
  },
  {
    title1: "151kwh",
    underTitle1: "Acual",
    title2: "32.2%",
    underTitle2: "Percentage",
    consumption: "Electricity difference"
  },
  {
    title1: "217.1m²",
    underTitle1: "Group 1",
    title2: "110.5m²",
    underTitle2: "Group 2",
    consumption: "Water"
  },
  {
    title1: "116.6m²",
    underTitle1: "Acual",
    title2: "50.9%",
    underTitle2: "Percentage",
    consumption: "Water Electricity"
  }
]


function Topbar() {

  return (
    <div className='top-bar'>
      <Typography variant="subtitle1">General consumption</Typography>
      <div className='list-boxs'>
          {listBoxs.map(({title1,title2,underTitle1,underTitle2,consumption}) => {
            return  <BoxTopBar title1={title1} title2={title2} underTitle1={underTitle1} underTitle2={underTitle2} consumption={consumption} key={uniqid()}/> 
           })}
      </div>

    </div>
  )
}

export default Topbar