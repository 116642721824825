import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuToggle from "./MenuToggle";
import uniqid from 'uniqid';


const listLi = [
  {
    title: "Comparison group 1",
    nameButton: "Office building 0384",
  },
  {
    title: "Floor",
    nameButton: "All floors",
  },
  {
    title: "Comparison group 2",
    nameButton: "Empire 0284",
  },
  {
    title: "Floor",
    nameButton: "All floor",
  },
  {
    title: "Time",
    nameButton: "Last weak",
  },
];

function Leftbar() {
  return (
    <div className="left-bar">
      <Typography variant="subtitle1">Filters</Typography>
      <ul>
        {listLi.map(({ title, nameButton }) => {
          return <MenuToggle title={title} nameButton={nameButton} key={uniqid()}/>;
        })}
      </ul>
    </div>
  );
}

export default Leftbar;
