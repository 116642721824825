import { Grid, Typography } from '@material-ui/core'
import React from 'react'

function BoxTopBar({title1,title2,underTitle1,underTitle2,consumption}) {
  return (
  <>
        <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        >

        <Grid 
        container
        direction="row"
        justifyContent="space-evenly"
        spacing={2}>
        <div>
          <Typography variant="h5">{title1}</Typography>
          <Typography variant="body2" className='title-list-li'>{underTitle1}</Typography>
        </div>
        <div>
          <Typography variant="h5">{title2}</Typography>
          <Typography variant="body2" className='title-list-li'>{underTitle2}</Typography>
        </div>
        </Grid>
        
        <Typography variant="subtitle2" className='title-list-li consumption'>{consumption}</Typography>
    </Grid>
  </>
  )
}

export default BoxTopBar