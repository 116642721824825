import React from "react";
import CardBottomBar from "./CardBottomBar";
// import ChartPolar from "./ChartPolar";

function Bottombar() {
  return (
    <div className="bottom-bar">
      <div className="card">
        <div className="card-left">
          <CardBottomBar />
            <div className="w-full ">
              {/* <ChartPolar /> */}
            </div>
        </div>
        <div className="card-right">
          <CardBottomBar />
        </div>
      </div>

    </div>
  );
}

export default Bottombar;
